.contractContainer {
    font-size: 12px;
    padding-inline: 1em;
}

.btnContainer {
    padding-inline: 1em;
}

.titleContainer {
}

.titleItem {
    font-size: 12px;
}
