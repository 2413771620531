.circle{
    width: 10px;
    height: 10px;
    border-radius: 50%;
    text-align: center;
    margin-top: 10px;
    background-color: #1a1a1a;
    position: relative;
}

.dot {
    height: 10px;
    width: 10px;
    margin-top: 10px;
    text-align: center;
    background-color: #686868;
    border-radius: 50%;
    display: inline-block;
}