@charset "UTF-8";

/* montserrat-200 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/montserrat-v12-latin-200.eot'); /* IE9 Compat Modes */
  src: local('Montserrat ExtraLight'), local('Montserrat-ExtraLight'),
       url('../fonts/montserrat-v12-latin-200.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v12-latin-200.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v12-latin-200.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v12-latin-200.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v12-latin-200.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/montserrat-v12-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Light'), local('Montserrat-Light'),
       url('../fonts/montserrat-v12-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v12-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v12-latin-300.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v12-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v12-latin-300.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat-v12-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('../fonts/montserrat-v12-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v12-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v12-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v12-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v12-latin-regular.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/montserrat-v12-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
       url('../fonts/montserrat-v12-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v12-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v12-latin-500.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v12-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v12-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/montserrat-v12-latin-600.eot'); /* IE9 Compat Modes */
  src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'),
       url('../fonts/montserrat-v12-latin-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v12-latin-600.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v12-latin-600.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v12-latin-600.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v12-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/montserrat-v12-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
       url('../fonts/montserrat-v12-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v12-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v12-latin-700.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v12-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v12-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-800 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('../fonts/montserrat-v12-latin-800.eot'); /* IE9 Compat Modes */
  src: local('Montserrat ExtraBold'), local('Montserrat-ExtraBold'),
       url('../fonts/montserrat-v12-latin-800.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v12-latin-800.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v12-latin-800.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v12-latin-800.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v12-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-900 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: url('../fonts/montserrat-v12-latin-900.eot'); /* IE9 Compat Modes */
  src: local('Montserrat Black'), local('Montserrat-Black'),
       url('../fonts/montserrat-v12-latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/montserrat-v12-latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../fonts/montserrat-v12-latin-900.woff') format('woff'), /* Modern Browsers */
       url('../fonts/montserrat-v12-latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../fonts/montserrat-v12-latin-900.svg#Montserrat') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'NanumSquare';
  src: url('../fonts/NanumSquareL.eot'); /* IE9 Compat Modes */
  src: url('../fonts/NanumSquareL.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/NanumSquareL.woff') format('woff'), /* Modern Browsers */
       url('../fonts/NanumSquareL.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/NanumSquareOTFLight.svg#017f9c78318bcde3d1dc4451e8eaca0a') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  300;
}

@font-face {
  font-family: 'NanumSquare';
  src: url('../fonts/NanumSquareR.eot'); /* IE9 Compat Modes */
  src: url('../fonts/NanumSquareR.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/NanumSquareR.woff') format('woff'), /* Modern Browsers */
       url('../fonts/NanumSquareR.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/NanumSquareOTFR.svg#9e23ceb0c858ca37642ba540402577eb') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'NanumSquare';
  src: url('../fonts/NanumSquareB.eot'); /* IE9 Compat Modes */
  src: url('../fonts/NanumSquareB.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/NanumSquareB.woff') format('woff'), /* Modern Browsers */
       url('../fonts/NanumSquareB.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/NanumSquareOTFB.svg#a41a1fda1fdf3dafd3394867a156b1cf') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  700;
}

@font-face {
  font-family: 'NanumSquare';
  src: url('../fonts/NanumSquareEB.eot'); /* IE9 Compat Modes */
  src: url('../fonts/NanumSquareEB.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/NanumSquareEB.woff') format('woff'), /* Modern Browsers */
       url('../fonts/NanumSquareEB.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/NanumSquareOTFExtraBold.svg#090d6763bc81b49309446d23fbd10e87') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  900;
}
