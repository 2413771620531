.button {
  margin: 0 0.5rem 0 0;
  height: 40px;
  min-width: 40px;
  color: #299fac !important;
  background-color: #fff;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
}

.button:hover {
  background-color: #08909f;
  transform: translateY(-1px);
  color: #fff !important;
}

.button > a:hover {
  text-decoration: none;
  color: #4d4d4d;
}
