.wrapper {
    .custom001 {
        display: flex;
        flex-flow: row nowrap;
    }

    .defaultImage {
        width: 2.5em;
        height: 2.5em;
        background-color: rgb(220, 220, 220);
        border-radius: 50%;
    }

    .priorityCol {
        span {
            color: #1aaaba;
        }
    }

    .checkbox {
        input {
            cursor: pointer !important;
        }
    }

    th,
    td {
        em,
        span {
            padding-top: 0.1em;
            text-align: left !important;
        }
    }

    td:nth-child(2) {
        max-width: 18em;
    }

    .nameRow {
        display: inline-flex;
        align-items: center;

        .img {
            width: 30px;
            background-image: none;

            img {
                width: 100%;
                height: auto;
                background-size: cover;
            }
        }

        .nameAddr {
            display: inline-flex;
            flex-direction: column;
            padding-left: 1em;

            span:first-child {
                display: inline-flex;
            }

            span.name {
                padding-top: 5px;
            }
            span.addr {
                color: rgba(1, 1, 1, 0.4);
                font-size: 0.6rem;
                padding-top: 5px;
                width: 15em;
            }
        }
    }
}
