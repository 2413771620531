.main {
    max-width: 100%;
    padding: 10px;
    display: flex;
    flex-flow: column nowrap;
}

.topSection {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 800px;
    align-self: center;

    .topSectionInfo,
    .modalContainer {
        display: flex;
        flex-flow: column nowrap;
        align-items: flex-start;
        overflow-x: auto;
        white-space: pre-wrap;
        white-space: -moz-pre-wrap;
        white-space: -pre-wrap;
        white-space: -o-pre-wrap;
        word-break: break-word;
        text-align: left;

        > p {
            margin: 2px 0px;
        }
    }

    .topSectionLogo {
        max-width: 150px;
    }
}

.bottomSection {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;

    .bottomSectionRow {
        padding: 10px;

        &:first-child {
            flex: 2;
            max-width: 200px;
        }

        &:nth-child(2) {
            flex: 1;
            max-width: 400px;
        }
    }
}

.defaultSection {
    display: flex;
    flex-flow: column nowrap;

    > h2 {
        font-weight: bold;
        color: black;
        margin-bottom: 20px;
        font-size: 1.2rem;

        position: relative;
        span {
            position: absolute;
            top: -5px;
            right: 1em;
            background-color: rgba(119, 119, 119, 0.2);
            padding: 0 !important;
            width: 1.5em;
            height: 1.5em;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            cursor: pointer;
        }
    }

    p {
        margin-bottom: 15px;
        color: black;
    }
}

.codeBlockContainer2 {
    align-self: center;
    max-width: 80%;
    padding: 10px;
    font-size: 0.6rem;
    background-color: black;
    color: white;
    border-radius: 4px;
    margin: 10px;
    display: flex;
    flex-flow: column nowrap;
    overflow-x: scroll;
}

.button {
    height: 40px;
    background-color: #299fac;
    color: #fff;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    align-self: flex-start;
    margin-right: auto;
    width: 150px !important;
    max-width: 150px !important;

    &.buttonDisabled {
        pointer-events: none;
        opacity: 0.5;
        color: white;
    }

    &:hover {
        background-color: #08909f;
        color: #fff;
    }
}

.btnContainer {
    display: flex;
    div {
        width: 15.4em;
    }
}

.setPrepForm {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;

    .button {
        align-self: flex-start;
    }
}

.table {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 10px;

    .tableRow {
        justify-content: space-between;
        margin: 5px;
        display: flex;
        flex-flow: row nowrap;

        > * {
        }

        > input {
        }

        .tableRowLabel {
            flex: 1;
            display: flex;
            align-items: center;
        }

        .tableRowInput {
            height: 35px !important;
            flex: 2 !important;
            border: 1px solid #c2c2c2 !important;
            border-radius: 4px !important;
            padding-left: 10px;

            &::placeholder {
                color: #c2c2c2;
            }
        }

        .tableRowInputValid {
            border: 1px solid green;
            width: 60%;
        }

        .tableRowInputInvalid {
            border: 1px solid red;
            width: 60%;
        }
    }
}

.tableRowInput2 {
    height: 35px !important;
    border: 1px solid #c2c2c2 !important;
    border-radius: 4px !important;
    padding-left: 10px;
}

.commissionBtn {
    margin-left: 49px;
}

.commissionUpdateInputRow {
    display: flex;
    flex-direction: row;
}

.tableOne {
    border: 1px solid #d6d3d1;
    max-width: 600px;
    margin: auto;

    th {
        padding: 5px;
        text-align: left;
        background-color: #f2f2f2;
        font-weight: bold;
    }

    td {
        padding: 5px;
        text-align: left;
        font-size: 0.8rem;

        &:first-child,
        th:first-child {
            width: 70%;
        }
    }

    td,
    th {
        border: 1px solid #d6d3d1;
    }
}

.tableOneContainer {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-self: center;
    padding: 10px;
}

.bold {
    font-weight: bold;
}

.inputGreen {
    border: 1px solid green !important;
    // width: 60%;
}

.inputRed {
    border: 1px solid red !important;
    // width: 60%;
}

.txResult {
    margin: 10px 0;
    font-size: 0.8rem;
    font-weight: bold;
    color: green;
    max-width: 100%;
    word-wrap: break-word;
}
