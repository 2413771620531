.main {
    max-width: 100%;
    padding: 10px;
    display: flex;
    flex-flow: column nowrap;

    .defaultSection {
        display: flex;
        flex-flow: column nowrap;

        > p {
            margin: 2px 0px;
            color: black;
        }

        > ul {
            margin-top: 5px;
        }

        > h2 {
            font-weight: bold;
            margin-bottom: 20px;
            font-size: 1.2rem;
            color: black;

            position: relative;
            span {
                position: absolute;
                top: -5px;
                right: 1em;
                background-color: rgba(119, 119, 119, 0.2);
                padding: 0 !important;
                width: 1.5em;
                height: 1.5em;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                cursor: pointer;
            }
        }
    }
}

.buttonSet {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    width: 55px;
    height: 30px;
    margin: 10px;
}

.topSection {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 700px;
    align-self: center;
}

.topSectionInfo,
.modalContainer {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    overflow-x: auto;
    white-space: pre-wrap;
    word-break: break-word;
    text-align: left;

    > p {
        margin: 2px 0px;
    }
}

.topSectionLogo {
    max-width: 150px;
}

.bottomSection {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    /* align-items: flex-start; */

    .bottomSectionRow {
        padding: 10px;

        &:first-child {
            flex: 2;
            max-width: 200px;
        }

        &:nth-child(2) {
            flex: 1;
            max-width: 400px;
        }

        p {
            margin: 2px 0px;
        }
    }
}

.codeBlockContainer2 {
    align-self: center;
    /* width: 100%; */
    max-width: 80%;
    padding: 10px;
    font-size: 0.6rem;
    background-color: black;
    color: white;
    border-radius: 4px;
    margin: 10px;
    display: flex;
    flex-flow: column nowrap;
    overflow-x: scroll;
}

.button {
    margin: 0 0.5rem 0 0;
    height: 40px;
    min-width: 40px;

    border: 1px solid #e2e2e2;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    align-self: flex-end;

    background-color: #08909f;
    color: #fff;

    &:hover {
        color: #299fac;
        background-color: #fff;
        border: 1px solid #299fac;
    }
}

.setPrepForm {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;

    .button {
        align-self: flex-start;
    }
}

.tableTitle {
    // font-size: 12px;
    color: black;
    margin-block: 10px;
    margin-top: 2.5em;
}

.table {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-self: center;
    border: 1px solid #d6d3d1;
    border-radius: 4px;
    font-size: 12px;

    .tableRow {
        display: flex;
        flex-flow: row nowrap;
        align-items: center;

        div {
            p {
                font-size: 0.9rem;
                font-weight: 600;
            }
        }

        .minusButton {
            $red: rgb(227, 58, 58);
            color: $red;
            background-color: #fff;
            border-radius: 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            position: relative;
            top: -1px;

            svg {
                cursor: pointer;
            }
        }

        > * {
            padding: 10px;

            &:first-child {
                flex-basis: 60%;
            }

            &:nth-child(2) {
                flex-basis: 30%;
            }

            &:last-child {
                flex-basis: 10%;
            }
        }
    }
}

.addBtnContainer {
    margin-top: 10px;

    button {
        font-size: 0.9rem;
        font-weight: bold;
        width: 80px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: #08909f;
        border: 1px solid #08909f;
        background-color: white;
        height: 35px;
        border-radius: 5px;
        cursor: pointer;

        svg {
            margin-left: 0.3em;
            margin-top: -2px;
        }
    }
}

.tableHead {
    color: #808080;
}

.input {
    width: 100%;
    height: 35px;
    padding-left: 10px;
    border: 1px solid #e5e5e5;
    background-color: #f5f5f5;

    // &Valid {
    //     border: 1px solid #4caf50;
    // }

    // &Invalid {
    //     border: 1px solid #f44336;
    // }
}

.tableRowLabel {
    /* margin: 0 4px; */
    flex: 1;
    display: flex;
    align-items: center;
}

.tableRowInput {
    height: 35px !important;
    flex: 2 !important;
    border: 2px solid #c2c2c2 !important;
    border-radius: 4px !important;

    &::placeholder {
        color: #c2c2c2;
    }

    &Valid {
        /* border: 1px solid #d6d3d1; */
        border: 1px solid green;
        width: 60%;
    }

    &Invalid {
        border: 1px solid red;
        width: 60%;
    }
}

/* .tableSetPrep > * { */
/*   /1* flex: 1; *1/ */
/* } */
/* .tableSetPrep tr { */
/*   display: flex; */
/*   flex-flow: column nowrap; */
/*   align-items: flex-end; */
/* } */
/* .tableSetPrep > thead { */
/*   margin-right: 5px; */
/* } */
