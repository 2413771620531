.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  padding: 40px 0px;
}
.modalClosed {
  display: none;
}
.modalOpen {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main {
  width: 80%;
  max-width: 800px;
  max-height: 100%;
  background-color: #ffffff;
  padding: 10px;
  border: 2px solid #c7c7c7;
  border-radius: 4px;
  /* overflow-x: hidden; */
  /* overflow-y: scroll; */
  cursor: text;
}
.mainSmall {
  min-height: 150px;
  height: auto;
  margin: 10px;
}
.mainBig {
  height: 100%;
}
